import { Card } from "@/components/Card";
import { ReactNode } from "react";

interface PromiseCardProps {
  icon: ReactNode;
  title: string;
  paragraph: string;
}

export const PromiseCard = ({ icon, title, paragraph }: PromiseCardProps) => {
  return (
    <Card
      raised
      className="flex flex-col gap-4 min-h-52 lg:min-h-64 transition-all"
    >
      <div className="w-full">{icon && icon}</div>

      <p className="font-semibold md:text-xl lg:texl-2xl">{title}</p>

      <p className="text-sm md:text-base lg:texl-xl text-stone-400">
        {paragraph}
      </p>
    </Card>
  );
};
