import { AxiosError } from "axios";
import { paymentsBffApi } from "@/services";
import { Destination, ICountry } from "@/interfaces/catalogs.interface";
import { SendingMethods } from "@/interfaces/quoter.interface";

const urlPartialBff = "/gpcatalogs";

export class CatalogService {
  static getCountries = async (language: string): Promise<ICountry[]> => {
    try {
      let lang: string = language;
      if (language !== "es" && language !== "en") {
        lang = "es";
      }

      const url = `${urlPartialBff}/country`;
      const response = await paymentsBffApi.get<ICountry[]>(url, {
        headers: {
          languageCode: lang,
        },
      });

      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error.response?.data);

        throw new Error(error.response?.data);
      }

      console.log(error);
      throw new Error("Unable to load countries");
    }
  };

  static getDestinations = async (
    sendingMethod: SendingMethods,
    countryCode: string
  ): Promise<Destination[]> => {
    try {
      const url = `${urlPartialBff}/destinations/${sendingMethod}/${countryCode}`;
      const response = await paymentsBffApi.get<Destination[]>(url);

      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error.response?.data);

        throw new Error(error.response?.data);
      }

      console.log(error);
      throw new Error("Unable to load countries");
    }
  };
}
