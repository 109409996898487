import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";
import { Destination, ICountry } from "@/interfaces/catalogs.interface";
import { CatalogService } from "@/services/catalogs.service";
import { SendingMethods } from "@/interfaces/quoter.interface";
import { AxiosError } from "axios";

interface CatalogState {
  countries: ICountry[];
  destinations: Destination[];
  getCountries: (lang: string) => Promise<void>;
  getDestinations: (
    sendingMethod: SendingMethods,
    countryCode: string
  ) => Promise<void>;
}

const storeApi: StateCreator<CatalogState> = (set) => ({
  countries: [],
  destinations: [],
  getCountries: async (lang: string) => {
    try {
      const response = await CatalogService.getCountries(lang);

      if (response) {
        const _countries = response;
        set(() => ({ countries: _countries }));
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data);
      }

      throw new Error("Unable to load countries");
    }
  },
  getDestinations: async (
    sendingMethod: SendingMethods,
    countryCode: string
  ) => {
    try {
      const response = await CatalogService.getDestinations(
        sendingMethod,
        countryCode
      );

      if (response) {
        const _destinations = response;
        set(() => ({ destinations: _destinations }));
      }
    } catch (error) {
      set(() => ({ destinations: [] }));

      if (error instanceof AxiosError) {
        throw new Error(error.response?.data);
      }

      throw new Error("Unable to load countries");
    }
  },
});

export const useCatalogStore = create<CatalogState>()(
  devtools(storeApi, { name: "catalog-storage" })
);
