import classNames from "classnames";
import QuoteIcon from "@/assets/Icons/quotes.svg?react";

interface TestimonialCardProps {
  title: string;
  subtitle?: string;
  paragraph?: string;
  imageUrl?: string;
  imageAlt?: string;
  className?: string;
  newFormat?: boolean;
}

export const TestimonialCard = ({
  title,
  subtitle,
  paragraph,
  imageUrl,
  imageAlt,
  className,
  newFormat = false,
}: TestimonialCardProps) => {
  if (newFormat)
    return (
      <div className="flex flex-col gap-4 h-full">
        <QuoteIcon className="w-10 h-10 text-stone-300" />
        <p className="text-xs md:text-sm font-semibold">{paragraph}</p>
        <div className="flex flex-1" />
        <div className="flex gap-4 items-center">
          <img
            className="h-12 w-12 rounded-full object-fill"
            src={imageUrl}
            alt={imageAlt || title}
          />
          <div className="w-fullflex flex-col justify-center">
            <p className="text-sm font-semibold">{title}</p>
            <p className="text-sm">{subtitle}</p>
          </div>
        </div>
      </div>
    );

  return (
    <div className={classNames("flex gap-4 items-start py-2", className)}>
      <div className="w-fit">
        <img
          className="h-16 w-16 rounded-full object-fill"
          src={imageUrl}
          alt={imageAlt || title}
        />
      </div>
      <div className="flex flex-col gap-2 flex-1">
        <div>
          <p className="text-sm font-semibold">{title}</p>
          <p className="text-sm">{subtitle}</p>
        </div>
        <p className="text-xs italic">”{paragraph}”</p>
      </div>
    </div>
  );
};
