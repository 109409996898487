import { useTranslation } from "react-i18next";
import PlaidLogo from "@/assets/Icons/plaid-logo.svg?react";
import PaymentMethodEn from "@/assets/Img/payment-method-en.png";
import PaymentMethodEs from "@/assets/Img/payment-method-es.png";
import WalletSendolaEn from "@/assets/Img/wallet-sendola-en.png";
import WalletSendolaEs from "@/assets/Img/wallet-sendola-en.png";
import SendolaAtms from "@/assets/Img/sendola-atms.jpg";
import { Card } from "@/components/Card";

import "./styles.module.css";

export const StickyCards = () => {
  const [t, i18n] = useTranslation("global");
  const lang = i18n.language;

  return (
    <div className="flex flex-col gap-8 w-full pb-10 lg:flex-row max-w-screen-sm lg:max-w-none">
      <div className="sticky pt-4 top-4 w-full pb-64 md:pb-96 md:h-full lg:pb-10 lg:top-[100px]">
        <h2
          className="font-semibold lg:text-6xl 2xl:text-7xl max-w-screen-lg"
          dangerouslySetInnerHTML={{ __html: t("Home.Section2.Title") }}
        />
        <p
          className="text-sm text-purple-700 lg:text-black lg:text-3xl 2xl:text-4xl lg:my-4"
          dangerouslySetInnerHTML={{ __html: t("Home.Section2.Subtitle") }}
        />
      </div>
      <div className="flex flex-col pb-20 items-end -mt-64 md:-mt-96 lg:mt-0 w-full">
        <Card
          className="!bg-indigo-500 text-white min-w-80 max-w-[44rem] h-64 sm:h-60 md:h-80 lg:h-96 w-full overflow-hidden sticky top-[140px] xs:top-[120px] md:top-[100px]"
          noPadding
          rest
        >
          <div className="flex flex-col h-full">
            <p className="px-4 pt-4 text-lg leading-tight lg:text-2xl	font-semibold">
              {t("Cards.Card1.Title")}
            </p>
            <div className="flex items-start h-full">
              <div className="w-[60%] pl-4 leading-tight">
                <PlaidLogo className="my-4 max-w-24 lg:h-9 text-white" />
                <p
                  className="text-base md:text-lg"
                  dangerouslySetInnerHTML={{
                    __html: t("Cards.Card1.Paragraph"),
                  }}
                />
              </div>
              <div
                className="w-[40%] lg:w-[60%] h-full max-sm:mr-2 background-class-card1"
                style={{
                  backgroundImage: `url(${
                    lang === "en" ? PaymentMethodEn : PaymentMethodEs
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "-24px 3px",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          </div>
        </Card>

        <Card
          className="!bg-pink-500 text-white min-w-80 max-w-[44rem] h-64 sm:h-64 md:h-80 lg:h-96 w-full overflow-hidden sticky top-[140px] xs:top-[120px] md:top-[100px] translate-y-[40px] md:translate-y-[50px]"
          noPadding
          rest
        >
          <div className="flex flex-col h-full">
            <p className="px-4 pt-4 text-lg leading-tight lg:text-2xl font-semibold">
              {t("Cards.Card2.Title")}
            </p>
            <div className="flex items-start h-full">
              <div
                className="w-[45%] h-full place-self-end background-class-card2"
                style={{
                  backgroundImage: `url(${
                    lang === "en" ? WalletSendolaEn : WalletSendolaEs
                  })`,
                  backgroundSize: "cover",
                  backgroundPosition: "0 20px",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <div className="text-[10px] md:text-base w-[55%] pr-2 pb-2 pt-4">
                <p className="text-base md:text-lg md:font-semibold leading-tight lg:-translate-x-8">
                  {t("Cards.Card2.Paragraph")}
                </p>
                <ul className="list-disc pl-4 text-xs md:text-sm lg:text-base mt-2">
                  <li>{t("Cards.Card2.Bullet1")}</li>
                  <li>{t("Cards.Card2.Bullet2")}</li>
                </ul>
              </div>
            </div>
          </div>
        </Card>

        <Card
          className="!bg-[#570d9e] text-white min-w-80 max-w-[44rem] h-64 sm:h-60 md:h-80 lg:h-96 w-full overflow-hidden sticky top-[140px] xs:top-[120px] md:top-[100px] translate-y-[80px] md:translate-y-[100px]"
          noPadding
          rest
        >
          <div className="flex flex-col h-full">
            <p className="px-4 pt-4 text-lg leading-tight lg:text-2xl	font-semibold">
              {t("Cards.Card3.Title")}
            </p>
            <div className="flex items-start h-full">
              <div className="w-[40%] pl-4 leading-tight pt-6">
                <p
                  className="text-base md:text-lg"
                  dangerouslySetInnerHTML={{
                    __html: t("Cards.Card3.Paragraph"),
                  }}
                />
              </div>
              <div
                className="w-[60%] h-full rounded-3xl"
                style={{
                  backgroundImage: `url(${SendolaAtms})`,
                  backgroundSize: "contain",
                  backgroundPosition: "right",
                  backgroundRepeat: "no-repeat",
                }}
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};
