import { Button } from "@/components/Button";
import classNames from "classnames";

interface CreateAccountBannerProps {
  title: string;
  subtitle: string;
  buttonText: string;
  link?: string;
  hideOnMobile?: boolean;
  className?: string;
}

export const CreateAccountBanner = ({
  title,
  subtitle,
  buttonText,
  link = "/banner-bank",
  hideOnMobile = false,
  className,
}: CreateAccountBannerProps) => {
  return (
    <div
      id="bank-account"
      className={classNames("w-full", className, {
        "hidden lg:block": hideOnMobile,
      })}
    >
      <div className="flex flex-col items-center px-6 py-6 rounded-2xl md:rounded-3xl bg-gradient-to-t from-[#5400C8] from-55% to-[#6E66FF] text-white">
        <h2 className="text-2xl md:text-3xl text-center font-semibold">
          {title}
        </h2>
        <h3 className="text-sm md:text-base text-center">{subtitle}</h3>
        <Button
          className="bg-white !text-indigo-600 mt-6"
          link={link}
          text={buttonText}
        />
      </div>
    </div>
  );
};
