import classNames from "classnames";

interface CountryPillProps {
  countryName: string;
  text: string;
  countryIcon: string;
  marked: boolean;
  className?: string;
}

export const CountryPill = ({
  countryName,
  text,
  countryIcon,
  marked,
  className,
}: CountryPillProps) => {
  return (
    <div
      className={classNames(
        "w-full h-fit rounded-full flex gap-2 bg-white shadow-lg p-2",
        className
      )}
    >
      <div className="flex w-full items-center gap-2">
        {countryIcon !== "Other" ? (
          <img
            className="w-8 h-8 rounded-full object-cover"
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${countryIcon}.svg`}
            alt={`${countryName}'s Flag`}
          />
        ) : (
          <div className="w-8 h-8 rounded-full bg-stone-400 flex-shrink-0" />
        )}
        <div className="w-full flex justify-between">
          <p className="w-full font-semibold text-xs md:text-sm lg:text-base">
            {countryName}
            {marked && <span className="text-stone-400">*</span>}
          </p>
          <p className="text-stone-400 font-semibold text-xs md:text-sm lg:text-base pr-2">
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};
