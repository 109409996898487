import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CircleBg from "@/assets/Img/background_circle.jpg";
import AccordionItem from "@/components/AccordionItem";

const Faqs = () => {
  const [t] = useTranslation("global");
  const [searchParams] = useSearchParams();

  const [selectedCategory, setSelectedCategory] = useState<string>('sendola');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [listToShow, setListToShow] = useState<Array<any>>();
  const [searchTerm, setSearchTerm] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchList, setSearchList] = useState<Array<any>>();
  const [showSearchList, setShowSearchList] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [categoryList, setCategoryList] = useState<Array<any>>();

  const typeFaqs = searchParams.get("type");
  const idFaqs = searchParams.get("id");

  const categoryListArray = [
    {
      questionId: "faqs-1",
      type: "sendola",
      title: t("Faqs.category.tab1.item1.title"),
      text: t("Faqs.category.tab1.item1.text"),
      url: "/faqs?type=sendola&id=faqs-1"
    },
    {
      questionId: "faqs-2",
      type: "sendola",
      title: t("Faqs.category.tab1.item2.title"),
      text: t("Faqs.category.tab1.item2.text"),
      url: "/faqs?type=sendola&id=faqs-2"
    },
    {
      questionId: "faqs-3",
      type: "sendola",
      title: t("Faqs.category.tab1.item3.title"),
      text: t("Faqs.category.tab1.item3.text"),
      url: "/faqs?type=sendola&id=faqs-3",
      typeList: "list-decimal",
      list: [
        t("Faqs.category.tab1.item3.point1"),
        t("Faqs.category.tab1.item3.point2"),
        t("Faqs.category.tab1.item3.point3"),
        t("Faqs.category.tab1.item3.point4"),
        t("Faqs.category.tab1.item3.point5"),
        t("Faqs.category.tab1.item3.point6"),
      ],
    },
    {
      questionId: "faqs-4",
      type: "sendola",
      title: t("Faqs.category.tab1.item4.title"),
      text: t("Faqs.category.tab1.item4.text"),
      text2: t("Faqs.category.tab1.item4.text2"),
      url: "/faqs?type=sendola&id=faqs-4",
      typeList: "list-disc",
      list: [
        t("Faqs.category.tab1.item4.point1"),
        t("Faqs.category.tab1.item4.point2"),
        t("Faqs.category.tab1.item4.point3"),
        t("Faqs.category.tab1.item4.point4")
      ]
    },
    {
      questionId: "faqs-5",
      type: "country",
      title: t("Faqs.category.tab1.item2.title"),
      text: t("Faqs.category.tab1.item2.text"),
      url: "/faqs?type=country&id=faqs-5"
    },
    {
      questionId: "faqs-6",
      type: "country",
      title: t("Faqs.category.tab2.item1.title"),
      text: t("Faqs.category.tab2.item1.text"),
      url: "/faqs?type=country&id=faqs-6"
    },
    {
      questionId: "faqs-7",
      type: "country",
      title: t("Faqs.category.tab2.item2.title"),
      text: t("Faqs.category.tab2.item2.text"),
      url: "/faqs?type=country&id=faqs-7"
    },
    {
      questionId: "faqs-8",
      type: "country",
      title: t("Faqs.category.tab2.item3.title"),
      text: t("Faqs.category.tab2.item3.text"),
      url: "/faqs?type=country&id=faqs-8",
      typeList: "list-decimal",
      list: [
        t("Faqs.category.tab2.item3.point1"),
        t("Faqs.category.tab2.item3.point2"),
        t("Faqs.category.tab2.item3.point3")
      ]
    },
    {
      questionId: "faqs-9",
      type: "payments",
      title: t("Faqs.category.tab3.item1.title"),
      text: t("Faqs.category.tab3.item1.text"),
      url: "/faqs?type=payments&id=faqs-9"
    },
    {
      questionId: "faqs-10",
      type: "payments",
      title: t("Faqs.category.tab3.item2.title"),
      text: t("Faqs.category.tab3.item2.text"),
      url: "/faqs?type=payments&id=faqs-10"
    },
    {
      questionId: "faqs-11",
      type: "payments",
      title: t("Faqs.category.tab3.item3.title"),
      text: t("Faqs.category.tab3.item3.text"),
      url: "/faqs?type=payments&id=faqs-11"
    },
    {
      questionId: "faqs-12",
      type: "payments",
      title: t("Faqs.category.tab3.item4.title"),
      text: t("Faqs.category.tab3.item4.text"),
      url: "/faqs?type=payments&id=faqs-12"
    },
    {
      questionId: "faqs-13",
      type: "payments",
      title: t("Faqs.category.tab3.item5.title"),
      text: t("Faqs.category.tab3.item5.text"),
      url: "/faqs?type=payments&id=faqs-13"
    },
    {
      questionId: "faqs-14",
      type: "payments",
      title: t("Faqs.category.tab3.item6.title"),
      text: t("Faqs.category.tab3.item6.text"),
      url: "/faqs?type=payments&id=faqs-14"
    },
    {
      questionId: "faqs-15",
      type: "payments",
      title: t("Faqs.category.tab3.item7.title"),
      text: t("Faqs.category.tab3.item7.text"),
      url: "/faqs?type=payments&id=faqs-15",
      typeList: "list-disc",
      list: [
        t("Faqs.category.tab3.item7.point1"),
        t("Faqs.category.tab3.item7.point2"),
        t("Faqs.category.tab3.item7.point3"),
      ]
    },
    {
      questionId: "faqs-16",
      type: "payments",
      title: t("Faqs.category.tab3.item8.title"),
      text: t("Faqs.category.tab3.item8.text"),
      url: "/faqs?type=payments&id=faqs-16"
    },
    {
      questionId: "faqs-17",
      type: "payments",
      title: t("Faqs.category.tab3.item9.title"),
      text: t("Faqs.category.tab3.item9.text"),
      url: "/faqs?type=payments&id=faqs-17"
    },
    {
      questionId: "faqs-18",
      type: "payments",
      title: t("Faqs.category.tab3.item10.title"),
      text: t("Faqs.category.tab3.item10.text"),
      url: "/faqs?type=payments&id=faqs-18"
    },
    {
      questionId: "faqs-19",
      type: "payments",
      title: t("Faqs.category.tab3.item11.title"),
      text: t("Faqs.category.tab3.item11.text"),
      url: "/faqs?type=payments&id=faqs-19"
    },
    {
      questionId: "faqs-20",
      type: "payments",
      title: t("Faqs.category.tab3.item12.title"),
      text: t("Faqs.category.tab3.item12.text"),
      url: "/faqs?type=payments&id=faqs-20"
    },
    {
      questionId: "faqs-21",
      type: "payments",
      title: t("Faqs.category.tab3.item13.title"),
      text: t("Faqs.category.tab3.item13.text"),
      url: "/faqs?type=payments&id=faqs-21"
    },
    {
      questionId: "faqs-22",
      type: "payments",
      title: t("Faqs.category.tab3.item14.title"),
      text: t("Faqs.category.tab3.item14.text"),
      url: "/faqs?type=payments&id=faqs-22"
    },
    {
      questionId: "faqs-23",
      type: "payments",
      title: t("Faqs.category.tab3.item15.title"),
      text: t("Faqs.category.tab3.item15.text"),
      url: "/faqs?type=payments&id=faqs-23",
      typeList: "list-decimal",
      list: [
        t("Faqs.category.tab3.item15.point1"),
        t("Faqs.category.tab3.item15.point2")
      ]
    },
    {
      questionId: "faqs-24",
      type: "payments",
      title: t("Faqs.category.tab3.item16.title"),
      text: t("Faqs.category.tab3.item16.text"),
      url: "/faqs?type=payments&id=faqs-24"
    },
    {
      questionId: "faqs-25",
      type: "payments",
      title: t("Faqs.category.tab3.item17.title"),
      text: t("Faqs.category.tab3.item17.text"),
      url: "/faqs?type=payments&id=faqs-25"
    },
    {
      questionId: "faqs-26",
      type: "payments",
      title: t("Faqs.category.tab3.item18.title"),
      text: t("Faqs.category.tab3.item18.text"),
      url: "/faqs?type=payments&id=faqs-26"
    },
    {
      questionId: "faqs-27",
      type: "payments",
      title: t("Faqs.category.tab3.item19.title"),
      text: t("Faqs.category.tab3.item19.text"),
      url: "/faqs?type=payments&id=faqs27"
    },
    {
      questionId: "faqs-28",
      type: "payments",
      title: t("Faqs.category.tab3.item20.title"),
      text: t("Faqs.category.tab3.item20.text"),
      url: "#"
    },
    {
      questionId: "faqs-29",
      type: "payments",
      title: t("Faqs.category.tab3.item21.title"),
      text: t("Faqs.category.tab3.item21.text"),
      url: "/faqs?type=payments&id=faqs-29"
    },
    {
      questionId: "faqs-30",
      type: "payments",
      title: t("Faqs.category.tab3.item22.title"),
      text: t("Faqs.category.tab3.item22.text"),
      url: "/faqs?type=payments&id=faqs-30"
    },
    {
      questionId: "faqs-31",
      type: "payments",
      title: t("Faqs.category.tab3.item23.title"),
      text: t("Faqs.category.tab3.item23.text"),
      url: "/faqs?type=payments&id=faqs-31"
    },
    {
      questionId: "faqs-32",
      type: "payments",
      title: t("Faqs.category.tab3.item24.title"),
      text: t("Faqs.category.tab3.item24.text"),
      url: "/faqs?type=payments&id=faqs-32"
    },
    {
      questionId: "faqs-33",
      type: "payments",
      title: t("Faqs.category.tab3.item25.title"),
      text: t("Faqs.category.tab3.item25.text"),
      url: "/faqs?type=payments&id=faqs-33"
    },
    {
      questionId: "faqs-34",
      type: "payments",
      title: t("Faqs.category.tab3.item26.title"),
      text: t("Faqs.category.tab3.item26.text"),
      url: "/faqs?type=payments&id=faqs-34"
    },
    {
      questionId: "faqs-35",
      type: "payments",
      title: t("Faqs.category.tab3.item27.title"),
      text: t("Faqs.category.tab3.item27.text"),
      url: "/faqs?type=payments&id=faqs-35"
    },
    {
      questionId: "faqs-36",
      type: "sendolaOne",
      title: t("Faqs.category.tab4.item1.title"),
      text: t("Faqs.category.tab4.item1.text"),
      url: "/faqs?type=sendolaOne&id=faqs-36"
    },
    {
      questionId: "faqs-37",
      type: "sendolaOne",
      title: t("Faqs.category.tab4.item2.title"),
      text: t("Faqs.category.tab4.item2.text"),
      url: "/faqs?type=sendolaOne&id=faqs-37",
      typeList: "list-none",
      list: [
        t("Faqs.category.tab4.item2.point1"),
        t("Faqs.category.tab4.item2.point2"),
        t("Faqs.category.tab4.item2.point3"),
        t("Faqs.category.tab4.item2.point4")
      ],
    },
    {
      questionId: "faqs-38",
      type: "sendolaOne",
      title: t("Faqs.category.tab4.item3.title"),
      text: t("Faqs.category.tab4.item3.text"),
      url: "/faqs?type=sendolaOne&id=faqs-38"
    },
    {
      questionId: "faqs-39",
      type: "sendolaOne",
      title: t("Faqs.category.tab4.item4.title"),
      text: t("Faqs.category.tab4.item4.text"),
      url: "/faqs?type=sendolaOne&id=faqs-39"
    },
  ];

  const handleTabClick = (tab: string) => {
    setSelectedCategory(tab);
  };

  useEffect(() => {
    setCategoryList(categoryListArray);
  }, [t])

  useEffect(() => {
    if (typeFaqs && typeFaqs !== selectedCategory) {
      setSelectedCategory(typeFaqs);
    } 
  }, [typeFaqs])

  useEffect(() => {
    if (categoryList) {
      const filterList = categoryList.filter(item => item.type === selectedCategory)
      setListToShow(filterList)
    }
  }, [selectedCategory, categoryList])

  useEffect(() => {
    if (categoryList) {
      const filteredItems = categoryList.filter((item) => {
        const inTitle = item.title.toLowerCase().includes(searchTerm.toLowerCase());
        const inText = item.text.toLowerCase().includes(searchTerm.toLowerCase());
        const inText2 = item.text2?.toLowerCase().includes(searchTerm.toLowerCase()) || false;
        const inList = item.list?.some((listItem: string) =>
          listItem.toLowerCase().includes(searchTerm.toLowerCase())
        ) || false;

        return inTitle || inText || inText2 || inList;
      });

      if(searchTerm.length > 2) {
        setShowSearchList(true);
        setSearchList(filteredItems);
      } else {
        setShowSearchList(false);
      }
    }
  }, [searchTerm])

  return (
    <div className="w-full">
      <div style={{background: "rgba(20, 20, 22)"}}>
        <div
          className="relative overflow-hidden bg-cover bg-no-repeat py-12 text-center h-[34rem] md:h-[35rem] lg:h-[40rem]"
          style={{background: `url(${CircleBg})`,backgroundSize: "cover", backgroundPosition: "bottom"}}>
          <div
            className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-fixed"
            style={{background: "linear-gradient(360deg, #141416 20%, rgba(68,78,116,0) 100%)"}}>
            <div className="flex h-full items-center justify-center mt-16">
              <div className="text-white">
                <h4 className="mb-16 text-3xl md:text-6xl pt-4 w-64 mx-auto font-thin">{t("Faqs.title")}</h4>
                <p className="text-sm	lg:text-lg lg:w-[40rem] mx-8 text-justify leading-8 pb-4">{t("Faqs.message")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="md:p-32" style={{background: "radial-gradient(circle at 78.3837890625% 0%, #000000 0%, 17.5%, rgba(0,0,0,0) 35%), radial-gradient(circle at 2.48046875% 26.044921875%, #572564 0%, 17.5%, rgba(87,37,100,0) 35%), radial-gradient(circle at 88.34147135416667% 75.58268229166667%, rgba(42,35,111,0.42) 0%, 32.5%, rgba(42,35,111,0) 65%), radial-gradient(circle at 5.753580729166666% 87.51953125%, #141416 0%, 25%, rgba(20,20,22,0) 50%), radial-gradient(circle at 48.9013671875% 49.521484375%, #141416 0%, 100%, rgba(20,20,22,0) 100%)"}}>
          <div className="md:p-10 py-20 md:py-10" style={{background: "rgba(20, 20, 22, 0.25)"}}>

            <div className="text-center px-6">
              <form className="mx-auto w-full">
                <div className="relative">
                  <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                    </svg>
                  </div>
                  <input
                    type="search"
                    id="search"
                    aria-label={t("Faqs.ariaSearch")}
                    className="w-full block p-4 ps-10 text-sm text-white border-b-2 border-white bg-transparent focus:ring-blue-500 focus:border-2 focus:border-sky-500 transition-all duration-200 ease-in"
                    placeholder={t("Faqs.search")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </form>

              <div className={`px-6 text-left ${showSearchList ? "" : "hidden"}`}>
                {searchList && searchList.length > 0 ? 
                  searchList.map((item, i) => {
                    return (
                      <AccordionItem
                        key={i}
                        title={item.title}
                        text={item.text}
                        typeList={item.typeList}
                        list={item.list}
                        text2={item.text2}
                        url={item.url}
                      />
                    )
                  }) :
                  <p className="text-white text-left py-5 text-sm">{t("Faqs.noFound")}</p>
                }
              </div>
            </div>

            <div className={`text-left px-6 py-6 lg:hidden ${showSearchList ? "hidden" : ""}`}>
              <p className="text-white text-sm	text-left mb-2">{t("Faqs.chooseCategory")}</p>
              <div className="inline-block relative w-full">
                <select
                  className="block appearance-none w-full bg-transparent border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline text-fuchsia-400"
                  value={selectedCategory}
                  onChange={(e) => setSelectedCategory(e.target.value)}
                >
                  <option value="sendola">{t("Faqs.category.tab1.title")}</option>
                  <option value="country">{t("Faqs.category.tab2.title")}</option>
                  <option value="payments">{t("Faqs.category.tab3.title")}</option>
                  <option value="sendolaOne">{t("Faqs.category.tab4.title")}</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                </div>
              </div>
            </div>

            <div className={`text-left px-6 py-6 max-lg:hidden tabs ${showSearchList ? "hidden" : ""}`}>
              <div className="block">
                <ul className="flex space-x-3 transition-all duration-300 -mb-px">
                  <li>
                    <p
                      className={`cursor-pointer inline-block py-4 pr-6 hover:text-gray-400 font-medium tablink whitespace-nowrap ${selectedCategory === "sendola" ? "border-b-indigo-600 text-fuchsia-400 active" : "text-white"}`}
                      data-tab="tabs-with-underline-1" 
                      role="tab"
                      onClick={() => handleTabClick("sendola")}
                    >
                      {t("Faqs.category.tab1.title")}
                    </p>
                  </li>
                  <li>
                    <p
                      className={`cursor-pointer inline-block py-4 pr-6 hover:text-gray-400 font-medium tablink whitespace-nowrap ${selectedCategory === "country" ? "border-b-indigo-600 text-fuchsia-400 active" : "text-white"}`}
                      data-tab="tabs-with-underline-2" 
                      role="tab"
                      onClick={() => handleTabClick("country")}
                    >
                      {t("Faqs.category.tab2.title")}
                    </p>
                  </li>
                  <li>
                    <p
                      className={`cursor-pointer inline-block py-4 pr-6 hover:text-gray-400 font-medium tablink whitespace-nowrap ${selectedCategory === "payments" ? "border-b-indigo-600 text-fuchsia-400 active" : "text-white"}`}
                      data-tab="tabs-with-underline-3" 
                      role="tab"
                      onClick={() => handleTabClick("payments")}
                    >
                      {t("Faqs.category.tab3.title")}
                    </p>
                  </li>
                  <li>
                    <p
                      className={`cursor-pointer inline-block py-4 pr-6 hover:text-gray-400 font-medium tablink whitespace-nowrap ${selectedCategory === "sendolaOne" ? "border-b-indigo-600 text-fuchsia-400 active" : "text-white"}`}
                      data-tab="tabs-with-underline-4" 
                      role="tab"
                      onClick={() => handleTabClick("sendolaOne")}
                    >
                      {t("Faqs.category.tab4.title")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>

            <div className={`px-6 ${showSearchList ? "hidden" : ""}`}>
              {listToShow && listToShow.map((item) => {
                return (
                  <AccordionItem
                    key={item.questionId}
                    questionId={item.questionId}
                    title={item.title}
                    text={item.text}
                    typeList={item.typeList}
                    list={item.list}
                    text2={item.text2}
                    url={window.location.origin + item.url}
                    defaultOpenId={idFaqs ? idFaqs : ""}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faqs
