import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import { Button } from "@/components/Button";
import { Card } from "@/components/Card";
import { PointCheck } from "@/components/PointCheck";

import {
  ArrowLongRightIcon,
  ArrowTopRightOnSquareIcon,
} from "@heroicons/react/24/outline";
import LinkIcon from "@/assets/Icons/link.svg?react";
import HouseIcon from "@/assets/Icons/house-estate.svg?react";
import BankIcon from "@/assets/Icons/bank-finance.svg?react";

import Adelanto from "@/assets/Img/adelanto.png";
import Contigo from "@/assets/Img/Contigo-Logo.png";
import BannerBankImg from "@/assets/Img/bannerBank.png";
import Mastercard from "@/assets/Img/mastercard.png";
import Micel from "@/assets/Img/miCel.png";
import Four from "@/assets/Img/four.png";
import Advance from "@/assets/Img/advance.png";
import Repatriation from "@/assets/Img/repatriation.png";
import Myphone from "@/assets/Img/myPhone.png";

const AuthUrl = import.meta.env.VITE_AUTH_DOMAIN;

export const BannerBank = () => {
  const [t] = useTranslation("global");
  const { i18n } = useTranslation();
  const lang = i18n.language;

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.replace("#", "");
      const element = document.getElementById(id);

      if (element) element.scrollIntoView();
    }
  }, []);

  return (
    <div className="w-full">
      <Helmet>
        <title>Sendola | Inicio</title>
      </Helmet>

      <div className="flex flex-col">
        <div className="flex justify-between gap-2 bg-gradient-to-t from-[#264093] from-56% to-[#0766E1] h-36 sm:h-52 md:h-72 lg:h-[300px] rounded-b-2xl overflow-hidden items-center	">
          <div className="flex w-7/12 md:w-1/2 flex-col justify-between text-white py-2 sm:py-4 lg:py-10 pl-6 lg:pl-10">
            <h1 className="text-lg md:text-2xl align-middle min-w-56 text-wrap max-w-xl">
              <span
                dangerouslySetInnerHTML={{
                  __html: t("BannerBank.title"),
                }}
              />
            </h1>
          </div>

          <div className="w-5/12 md:w-1/2 flex justify-center relative overflow-hidden min-h-40">
            <img
              className="h-full w-full object-contain absolute min-h-40"
              src={BannerBankImg}
              id="bannerBank"
              alt="Banner Bank"
            />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center px-6 py-2 gap-4 mt-6">
        <p className="text-xl">{t("BannerBank.subtitle")}</p>
        <p className="text-sm text-violet-600">{t("BannerBank.text")}</p>
        <div className="flex flex-col gap-3 w-full max-w-screen-sm 2xl:max-w-screen-md">
          <Card className="relative w-full">
            <div>
              <img
                className="ml-auto"
                src={Mastercard}
                id="Mastercard"
                alt="Mastercard"
              />
            </div>
            <div>
              <PointCheck
                text={t("BannerBank.section1.bullet1")}
                backgroundColor="bg-gradient-to-t from-[#264093] from-56% to-[#0766E1]"
              />
              <PointCheck
                text={t("BannerBank.section1.bullet2")}
                backgroundColor="bg-gradient-to-t from-[#264093] from-56% to-[#0766E1]"
              />
              <PointCheck
                text={t("BannerBank.section1.bullet3")}
                backgroundColor="bg-gradient-to-t from-[#264093] from-56% to-[#0766E1]"
              />
              <PointCheck
                text={t("BannerBank.section1.bullet4")}
                backgroundColor="bg-gradient-to-t from-[#264093] from-56% to-[#0766E1]"
              />
              <PointCheck
                text={t("BannerBank.section1.bullet5")}
                backgroundColor="bg-gradient-to-t from-[#264093] from-56% to-[#0766E1]"
              />
              <div className="text-center">
                <a
                  className="text-xs underline"
                  href="https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/Sendola+Terms+of+Service++(8092025).pdf"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  {t("BannerBank.section1.terms")}
                </a>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div className="w-full flex flex-col items-center px-6 py-2 gap-4 mt-2 pb-0">
        <div className="flex flex-col gap-3 w-full max-w-screen-sm 2xl:max-w-screen-md">
          <p className="text-xl font-semibold">
            <span
              dangerouslySetInnerHTML={{
                __html: t("BannerBank.section2.title"),
              }}
            />
          </p>
          <p className="text-sm text-violet-600">
            <span
              dangerouslySetInnerHTML={{
                __html: t("BannerBank.section2.subtitle"),
              }}
            />
          </p>
          <Card className="relative w-full">
            <div>
              <PointCheck
                text={t("BannerBank.section2.bullet1")}
                backgroundColor="bg-gradient-to-t from-[#FF007C] from-56% to-[#6E66FF]"
              />
              <PointCheck
                text={t("BannerBank.section2.bullet2")}
                backgroundColor="bg-gradient-to-t from-[#FF007C] from-56% to-[#6E66FF]"
              />
              <PointCheck
                text={t("BannerBank.section2.bullet3")}
                backgroundColor="bg-gradient-to-t from-[#FF007C] from-56% to-[#6E66FF]"
              />
              <PointCheck
                text={t("BannerBank.section2.bullet4")}
                backgroundColor="bg-gradient-to-t from-[#FF007C] from-56% to-[#6E66FF]"
              />
              <div className="flex items-center justify-center">
                {lang === "es" ? (
                  <img className="h-5 w-20" src={Adelanto} alt="Adelanto" />
                ) : (
                  <img className="h-4 w-21" src={Advance} alt="Advance" />
                )}
                {lang === "es" ? (
                  <img className="h-3 w-10 mx-6" src={Micel} alt="Micel" />
                ) : (
                  <img className="h-4 w-16 mx-4" src={Myphone} alt="Myphone" />
                )}
                {lang === "es" ? (
                  <img className="h-5 w-16" src={Contigo} alt="Contigo" />
                ) : (
                  <img
                    className="h-4 w-20"
                    src={Repatriation}
                    alt="Repatriation"
                  />
                )}
              </div>
              <div className="flex pl-8 mt-2">
                <a className="text-sm text-indigo-500 flex" href="/#benefits">
                  {t("BannerBank.section2.button")}
                  <ArrowLongRightIcon className="ml-2 w-5 h-5" />
                </a>
              </div>
            </div>
          </Card>
        </div>
      </div>

      <div
        className="flex flex-col items-center gap-4 w-full p-6 pb-0"
        id="signIn"
      >
        <div className="flex flex-col gap-4 items-center w-full max-w-screen-sm 2xl:max-w-screen-md">
          <div className="inline-flex justify-around w-full">
            <Button
              className="shadow-xl py-4 min-w-40 text-base"
              text={t("BannerBank.buttonConnect")}
              link={`${AuthUrl}/sign-in`}
              size="sm"
              icon={<LinkIcon className="h-5 w-5" />}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center px-6 py-2 gap-4 mt-2 pb-0 mb-10">
        <div className="flex flex-col gap-3 w-full max-w-screen-sm 2xl:max-w-screen-md mt-4">
          <p className="text-2xl font-semibold text-violet-600">
            <span
              dangerouslySetInnerHTML={{
                __html: t("BannerBank.section3.title"),
              }}
            />
          </p>
          <p className="text-xl">
            <span
              dangerouslySetInnerHTML={{
                __html: t("BannerBank.section3.subtitle"),
              }}
            />
          </p>
          <div className="text-center mb-6 mt-4">
            <p>{t("BannerBank.section3.request")}</p>
            <img className="h-16 w-12 mx-auto my-2" src={Four} alt="4" />
            <p>{t("BannerBank.section3.request2")}</p>
          </div>
          <div>
            <div className="flex mb-4">
              <div className="rounded-full h-9 w-9 p-2 mr-2.5 bg-gradient-to-t from-[#680BAD] from-97% to-[#6E66FF]">
                <HouseIcon className="w-5 h-5" />
              </div>
              <div>
                <p className="text-xs font-semibold">
                  {t("BannerBank.section3.location1.title")}
                </p>
                <ul className="list-disc ml-4">
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("BannerBank.section3.location1.point1"),
                    }}
                    className="text-xs my-2"
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("BannerBank.section3.location1.point2"),
                    }}
                    className="text-xs my-2"
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: t("BannerBank.section3.location1.point3"),
                    }}
                    className="text-xs my-2"
                  />
                </ul>
                <a
                  href="https://maps.app.goo.gl/soCda7upA5kYwKwk7"
                  target="_blank"
                  className="text-indigo-500 text-xs flex items-center font-semibold"
                >
                  <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />
                  {t("BannerBank.section3.location1.textLink")}
                </a>
              </div>
            </div>
            <div className="flex mb-4">
              <div className="rounded-full h-9 w-9 p-2 mr-2.5 bg-gradient-to-t from-[#680BAD] from-97% to-[#6E66FF]">
                <BankIcon className="w-5 h-5" />
              </div>
              <div>
                <p className="text-xs font-semibold">
                  {t("BannerBank.section3.location2.title")}
                </p>
                <p className="text-xs flex my-2">
                  <a
                    href="https://locations.bannerbank.com/wa/yakima"
                    target="_blank"
                    className="text-indigo-500 text-xs flex items-center font-semibold"
                  >
                    <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />
                    {t("BannerBank.section3.location2.point1")}
                  </a>
                  {t("BannerBank.section3.location2.washington")}
                </p>
                <p className="text-xs flex my-2">
                  <a
                    href="https://locations.bannerbank.com/wa/sunnyside"
                    target="_blank"
                    className="text-indigo-500 text-xs flex items-center font-semibold"
                  >
                    <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />
                    {t("BannerBank.section3.location2.point2")}
                  </a>
                  {t("BannerBank.section3.location2.washington")}
                </p>
                <p className="text-xs flex my-2">
                  <a
                    href="https://locations.bannerbank.com/or/hermiston"
                    target="_blank"
                    className="text-indigo-500 text-xs flex items-center font-semibold"
                  >
                    <ArrowTopRightOnSquareIcon className="w-4 h-4 mr-1" />
                    {t("BannerBank.section3.location2.point3")}
                  </a>
                  {t("BannerBank.section3.location2.oregon")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
