import { Button } from "@/components/Button";
import classNames from "classnames";

interface JoinBankNetworkBannerProps {
  title: string;
  subtitle: string;
  buttonText: string;
  hideOnMobile?: boolean;
  className?: string;
  onClickButton?: () => void;
}

export const JoinBankNetworkBanner = ({
  title,
  subtitle,
  buttonText,
  hideOnMobile = false,
  className,
  onClickButton,
}: JoinBankNetworkBannerProps) => {
  return (
    <div
      id="bank-account"
      className={classNames("w-full", className, {
        "hidden lg:block": hideOnMobile,
      })}
    >
      <div className="flex flex-col items-center px-6 py-6 rounded-2xl md:rounded-3xl bg-stone-200">
        <h2 className="text-2xl md:text-3xl text-center font-semibold">
          {title}
        </h2>
        <h3 className="text-sm md:text-base text-center">{subtitle}</h3>
        <Button
          className="bg-white mt-6 border-none !text-black"
          onClick={onClickButton}
          text={buttonText}
        />
      </div>
    </div>
  );
};
