import classNames from "classnames";

interface CountryProgressProps {
  index: number;
  text: string;
  color: string;
  percentageFill: number;
  className?: string;
}

export const CountryProgress = ({
  index,
  text,
  color,
  percentageFill,
  className,
}: CountryProgressProps) => {
  return (
    <div
      className={classNames(
        "w-full h-10 rounded-full flex gap-2 bg-gray-100",
        className
      )}
    >
      <div
        style={{ width: "calc(100% - 20px)" }}
        className="flex items-center gap-2 justify-end relative"
      >
        <div
          style={{
            width: `calc(28px + ${percentageFill}%)`,
            background: color,
            transitionDelay: `${index * 100}ms`,
          }}
          className={classNames(
            "min-w-1 duration-500 h-10 rounded-r-full absolute -left-2",
            {
              "transition-all": percentageFill > 0,
              "transition-none": percentageFill === 0,
            }
          )}
        />
        <p
          className={classNames(
            "font-semibold duration-500 text-xs md:text-sm lg:text-base pr-2 z-10",
            {
              "text-stone-500": percentageFill < 95,
              "text-white": percentageFill >= 95,
              "transition-all": percentageFill > 0,
              "transition-none": percentageFill === 0,
            }
          )}
        >
          {text}
        </p>
      </div>
    </div>
  );
};
