import { FocusEvent, ReactNode, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import LoadingIcon from "@/assets/Icons/loading.svg?react";
import classNames from "classnames";

export interface SelectOption {
  label: string;
  value: string;
  customElement?: ReactNode;
}

interface SelectProps {
  id?: string;
  name?: string;
  options: SelectOption[];
  value?: string;
  defaultValue?: string;
  size?: "sm" | "md" | "lg";
  placeholder?: string;
  disabled?: boolean;
  loading?: boolean;
  alignMenuRight?: boolean;
  error?: boolean;
  errorMessage?: string;
  block?: boolean;
  className?: string;
  containerClassName?: string;
  icon?: ReactNode;
  onChange?: (newValue: string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
}

export const Select = ({
  id,
  name,
  options,
  value,
  defaultValue,
  size = "md",
  placeholder,
  disabled = false,
  loading = false,
  alignMenuRight = false,
  error,
  errorMessage,
  block = true,
  className,
  containerClassName,
  icon,
  onChange,
  onBlur,
  onFocus,
}: SelectProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");

  const ref = useOutsideClick(() => {
    if (isMenuOpen) setIsMenuOpen(false);
  });

  const handleClick = () => {
    if (disabled || loading) return;

    setIsMenuOpen(!isMenuOpen);
  };

  const handleChange = (change: SelectOption) => {
    if (value === undefined || value === null) {
      setSelectedOption(change.value);
    }
    if (onChange) {
      onChange(change.value);
    }
    setIsMenuOpen(false);
  };

  return (
    <div
      className={classNames(
        "relative flex flex-col gap-1",
        containerClassName,
        {
          "w-full": block,
          "w-72": !block,
        }
      )}
    >
      <input
        className="hidden"
        hidden
        type="text"
        id={id}
        name={name}
        value={value || selectedOption}
        defaultValue={defaultValue}
        readOnly
        disabled
      />
      <div
        ref={ref}
        onClick={handleClick}
        onBlur={!disabled && !loading && onBlur ? onBlur : undefined}
        onFocus={!disabled && !loading && onFocus ? onFocus : undefined}
        className={classNames(
          "flex justify-between items-center px-3 py-2 rounded-lg border-2 w-full cursor-pointer hover:bg-gray-50 focus:border-indigo-600 select-none",
          {
            "border-red-500": error,
            "border-gray-300": !error,
            "text-sm": size === "sm",
            "text-base": size === "md",
            "text-lg": size === "lg",
            "bg-gray-100": disabled || loading,
          },
          className
        )}
      >
        {icon && icon}
        <p>
          {value ? value : selectedOption ? selectedOption : placeholder || ""}
        </p>
        {loading ? (
          <LoadingIcon className="w-5 h-5 animate-spin text-gray-400" />
        ) : (
          <ChevronDownIcon className="h-5 w-5" />
        )}
      </div>

      {isMenuOpen && (
        <div
          className={classNames(
            "absolute overflow-x-hidden overflow-y-auto top-12 w-full min-w-max max-h-44 min-h-12 shadow-lg bg-white border-gray-300 border-[1px] rounded-lg z-10",
            {
              "left-0": !alignMenuRight,
              "right-0": alignMenuRight,
            }
          )}
        >
          {options?.length > 0 ? (
            options.map((option, index) => (
              <div
                key={`option-${index}`}
                className={classNames(
                  "flex gap-2 px-3 py-2 border-b-2 border-gray-100 hover:bg-gray-100 text-gray-600 cursor-pointer select-none",
                  {
                    "flex-row-reverse": alignMenuRight,
                  }
                )}
                onClick={() => handleChange(option)}
              >
                {option.customElement && option.customElement}
                <p
                  className={classNames("min-w-fit w-full", {
                    "text-right": alignMenuRight,
                  })}
                >
                  {option.label}
                </p>
              </div>
            ))
          ) : (
            <p className="text-sm text-gray-400 w-full h-12 px-4 flex items-center justify-center">
              No options
            </p>
          )}
        </div>
      )}

      {error && errorMessage && (
        <p className="text-red-500 text-sm">{errorMessage}</p>
      )}
    </div>
  );
};
