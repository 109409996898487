import { StateCreator, create } from "zustand";
import { devtools } from "zustand/middleware";
import { Quote } from "@/interfaces/quoter.interface";
import { AxiosError } from "axios";
import { QuoterService } from "@/services/quoter.service";

interface QuoterState {
  quote: Quote | null;
  setQuote: (quote: Quote | null) => void;
  getQuote: (
    countryCode: string,
    currencyCode: string,
    amount: number,
    payerQuote: string
  ) => Promise<void>;
}

const storeApi: StateCreator<QuoterState> = (set) => ({
  quote: null,
  setQuote(quote) {
    set(() => ({ quote }));
  },
  getQuote: async (
    countryCode: string,
    currencyCode: string,
    amount: number,
    payerQuote: string
  ) => {
    try {
      const response = await QuoterService.getQuote(
        countryCode,
        currencyCode,
        amount,
        payerQuote
      );

      if (response) {
        set(() => ({ quote: response }));
      }
    } catch (error) {
      set(() => ({ quote: null }));
      if (error instanceof AxiosError) {
        throw new Error(error.response?.data);
      }

      throw new Error("Unable to load countries");
    }
  },
});

export const useQuoterStore = create<QuoterState>()(
  devtools(storeApi, { name: "quoter-storage" })
);
