import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Card } from "@/components/Card";
import { CountryPill } from "@/components/CountryPill";
import { CountryProgress } from "@/components/CountryProgress";

interface CountriesGraphicProps {
  className?: string;
}

export const CoutriesGraphic = ({ className }: CountriesGraphicProps) => {
  const { t } = useTranslation("global");
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement>(null);

  const countries = useMemo(() => {
    return [
      {
        icon: "MX",
        name: t("Countries.MX"),
        value: "$60,925",
        percentage: 100,
        marked: false,
      },
      {
        icon: "IN",
        name: t("Countries.IN"),
        value: "$15,808",
        percentage: 25.9,
        marked: false,
      },
      {
        icon: "GT",
        name: t("Countries.GT"),
        value: "$14,078",
        percentage: 23.1,
        marked: false,
      },
      {
        icon: "PH",
        name: t("Countries.PH"),
        value: "$12,837",
        percentage: 21.1,
        marked: false,
      },
      {
        icon: "VN",
        name: t("Countries.VN"),
        value: "$7,895",
        percentage: 13,
        marked: false,
      },
      {
        icon: "SV",
        name: t("Countries.SV"),
        value: "$6,711",
        percentage: 11,
        marked: false,
      },
      {
        icon: "HN",
        name: t("Countries.HN"),
        value: "$5,933",
        percentage: 9.7,
        marked: false,
      },
      {
        icon: "CO",
        name: t("Countries.CO"),
        value: "$2,544",
        percentage: 8.2,
        marked: true,
      },
      {
        icon: "BR",
        name: t("Countries.BR"),
        value: "$1,146",
        percentage: 5.9,
        marked: true,
      },
      {
        icon: "NI",
        name: t("Countries.NI"),
        value: "$909",
        percentage: 5.5,
        marked: false,
      },
    ];
  }, [t]);

  const otherCountries = useMemo(() => {
    return [
      {
        icon: "CA",
        name: t("Countries.CA"),
        value: "$511",
        percentage: 4.8,
        marked: false,
      },
      {
        icon: "PA",
        name: t("Countries.PA"),
        value: "$415",
        percentage: 3.7,
        marked: false,
      },
      {
        icon: "AR",
        name: t("Countries.AR"),
        value: "$117",
        percentage: 3.2,
        marked: true,
      },
      {
        icon: "CL",
        name: t("Countries.CL"),
        value: "$12",
        percentage: 2,
        marked: true,
      },
    ];
  }, [t]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={ref}
      className={classNames(
        "w-full flex flex-col max-w-screen-xl gap-6",
        className
      )}
    >
      <p className="w-full text-center text-lg md:text-xl lg:text-2xl font-semibold">
        {t("ForPartners.Section3.Subtitle")}
      </p>
      <div className="w-full flex flex-col gap-1 bg-white rounded-3xl">
        {countries.map((country, index) => (
          <div className="flex items-center w-full pl-5 sm:pl-0">
            <div
              key={`country-${index}`}
              className="hidden sm:flex bg-gray-100 w-[50%] sm:!w-[40%] md:!w-[30%] lg:!w-[25%] h-fit rounded-full gap-1 pl-2 pr-10 py-2 justify-end"
            >
              <p className="text-gray-500 font-semibold text-xs text-right sm:text-sm md:text-base">
                {country.name}
                <span className="text-gray-400">{country.marked && "*"}</span>
              </p>
            </div>
            <div className="w-12 flex justify-center overflow-visible -mx-5 z-10">
              {country.marked && (
                <span className="block sm:hidden text-xl text-gray-400 w-0 z-20">
                  *
                </span>
              )}
              <div className="w-12 h-12 p-1 rounded-full bg-white flex items-center justify-center">
                <img
                  className="w-8 h-8 rounded-full object-cover flex-shrink-0"
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.icon}.svg`}
                  alt={`${country.name}'s Flag`}
                />
              </div>
            </div>
            <CountryProgress
              key={`country-pill-${index}`}
              index={index}
              color="#7329f6"
              className={`w-[50%] sm:!w-[60%] md:!w-[75%] lg:!w-[80%]`}
              percentageFill={!isVisible ? 0 : country.percentage}
              text={country.value}
            />
          </div>
        ))}
        {otherCountries.map((country, index) => (
          <div className="flex sm:hidden items-center w-full pl-5 sm:pl-0">
            <div className="w-12 flex justify-center overflow-visible -mx-5 z-10">
              {country.marked && (
                <span className="block sm:hidden text-xl text-gray-400 w-0 z-20">
                  *
                </span>
              )}
              <div className="w-12 h-12 p-1 rounded-full bg-white flex items-center justify-center">
                <img
                  className="w-8 h-8 rounded-full object-cover flex-shrink-0"
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.icon}.svg`}
                  alt={`${country.name}'s Flag`}
                />
              </div>
            </div>
            <CountryProgress
              index={index + countries?.length || 5}
              key={`country-pill-${index}`}
              color="#7329f6"
              className="w-[50%] sm:!w-[60%] md:!w-[75%] lg:!w-[80%]"
              percentageFill={!isVisible ? 0 : country.percentage}
              text={country.value}
            />
          </div>
        ))}
      </div>

      <p className="hidden sm:block w-full text-center text-lg font-semibold lg:text-2xl">
        {t("ForPartners.Section3.OtherCountries")}
      </p>
      <Card
        raised
        className="hidden w-full items-center !rounded-3xl !bg-gray-100 sm:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-6 p-4 sm:p-6"
      >
        {otherCountries.map((country, index) => (
          <CountryPill
            key={`country-${index}`}
            countryName={country.name}
            countryIcon={country.icon}
            marked={country.marked}
            text={country.value}
          />
        ))}
      </Card>
      <div>
        <p className="text-xs lg:text-sm text-center text-stone-500">
          {t("ForPartners.Section3.Disclaimer1")}
        </p>
        <p className="text-xs lg:text-sm text-center text-stone-500">
          {t("ForPartners.Section3.Disclaimer2")}
        </p>
        <p className="text-sm lg:text-base text-center text-stone-500 mt-2">
          {t("ForPartners.Section3.Disclaimer3")}
        </p>
      </div>
    </div>
  );
};
