import ReactHelmet from "react-helmet";
import { useTranslation } from "react-i18next";

const legalLinkClassName =
  "text-base md:text-lg font-semibold underline list-disc w-fit";

export const Legal = () => {
  const [t] = useTranslation("global");

  return (
    <>
      <ReactHelmet>Información Legal | Sendola</ReactHelmet>

      <div className="flex flex-col gap-4 w-full min-h-full bg-white px-6 pt-6 pb-40">
        <h1 className="text-2xl md:text-3xl lg:text-4xl font-semibold">
          {t("Legal.Title")}
        </h1>
        <h3 className="text-sm md:text-base text-gray-500">
          {t("Legal.Address")}
        </h3>
        <ul className="flex flex-col gap-2 pl-4">
          <a
            href="https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/PrivacyPolicy.pdf"
            className={legalLinkClassName}
          >
            <li>{t("Legal.Bullet1")}</li>
          </a>
          <a
            href="https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/ElectronicCommunicationsDisclosuresandAgreement.pdf"
            className={legalLinkClassName}
          >
            <li>{t("Legal.Bullet2")}</li>
          </a>
          <a
            href="https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/USAPatriotAct.pdf"
            className={legalLinkClassName}
          >
            <li>{t("Legal.Bullet3")}</li>
          </a>
          <a
            href="https://s3.us-west-1.amazonaws.com/sendola.io/Disclosures+y+Legales+/Sendola.io/Sendola+Terms+of+Service++(8092025).pdf"
            className={legalLinkClassName}
          >
            <li>{t("Legal.Bullet6")}</li>
          </a>
        </ul>
      </div>
    </>
  );
};
