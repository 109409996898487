import { AxiosError } from "axios";
import { paymentsBffApi } from "@/services";
import { Quote } from "@/interfaces/quoter.interface";

const urlPartialBff = "/Quote";

export class QuoterService {
  static getQuote = async (
    countryCode: string,
    currencyCode: string,
    amount: number,
    payerQuote: string
  ): Promise<Quote> => {
    try {
      const url = `${urlPartialBff}/${countryCode}/${currencyCode}/${amount}/${payerQuote}`;
      const response = await paymentsBffApi.get<Quote>(url);

      return response?.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(error.response?.data);

        throw new Error(error.response?.data);
      }

      console.log(error);
      throw new Error("Unable to get quote");
    }
  };
}
