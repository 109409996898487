import { useTranslation } from "react-i18next";
import classNames from "classnames";

interface NationalMarketBannerProps {
  className?: string;
}

export const NationalMarketBanner = ({
  className,
}: NationalMarketBannerProps) => {
  const { t } = useTranslation("global");

  return (
    <div
      className={classNames(
        "w-full rounded-3xl bg-gray-100 grid items-center grid-cols-1 xs:grid-cols-2 md:grid-cols-4 gap-6 px-6 py-10 lg:px-10",
        className
      )}
    >
      <p
        className="text-xl sm:text-3xl md:text-2xl lg:text-4xl font-bold inner-span-indigo"
        dangerouslySetInnerHTML={{
          __html: t("ForPartners.Section2.Title"),
        }}
      />

      <div>
        <p className="text-xl lg:text-2xl font-semibold">
          {t("ForPartners.Section2.ImmigrantsCount")}
        </p>
        <p className="text-sm xl:text-base md:min-h-12 -mb-2 font-semibold text-stone-400">
          {t("ForPartners.Section2.ImmigrantsText")}
        </p>
      </div>

      <div>
        <p className="text-xl lg:text-2xl font-semibold">
          {t("ForPartners.Section2.ForeignCount")}
        </p>
        <p className="text-sm xl:text-base md:min-h-12 -mb-2 font-semibold text-stone-400">
          {t("ForPartners.Section2.ForeignText")}
        </p>
      </div>

      <div>
        <p className="text-xl lg:text-2xl font-semibold">
          {t("ForPartners.Section2.RemittancesCount")}
        </p>
        <p className="text-sm xl:text-base md:min-h-12 -mb-2 font-semibold text-stone-400">
          {t("ForPartners.Section2.RemittancesText")}
        </p>
      </div>
    </div>
  );
};
