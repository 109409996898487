import { Card } from "@/components/Card";
import classNames from "classnames";

interface StepCardProps {
  step: number;
  title: string;
  content: string;
  imageSrc?: string;
  stepNumberVariant?: "primary" | "secondary";
  titleInline?: boolean;
  className?: string;
}

const primaryStep = "bg-indigo-500 text-white";
const secondaryStep = "border-2 border-indigo-500 text-indigo-500";

export const StepCard = ({
  step,
  title,
  content,
  imageSrc,
  stepNumberVariant = "primary",
  titleInline = false,
  className,
}: StepCardProps) => {
  return (
    <Card
      className={classNames(
        "flex flex-col gap-3 pb-20 !rounded-3xl",
        className
      )}
    >
      <div className="w-full flex gap-4 items-center">
        <span
          className={classNames(
            "h-12 w-12 flex-shrink-0 rounded-full flex justify-center items-center text-3xl font-bold",
            {
              [primaryStep]: stepNumberVariant === "primary",
              [secondaryStep]: stepNumberVariant === "secondary",
            }
          )}
        >
          {step}
        </span>

        <p
          className={classNames("text-xl font-semibold", {
            hidden: !titleInline,
          })}
        >
          {title}
        </p>
      </div>

      <p
        className={classNames("text-xl font-semibold", {
          hidden: titleInline,
        })}
      >
        {title}
      </p>
      <p className="text-sm md:text-base text-stone-500">{content}</p>

      <div className="flex flex-1" />

      <div className="w-full flex justify-center -mb-20 overflow-hidden">
        {imageSrc && (
          <img
            className="w-[80%] sm:w-[55%] max-w-72 max-h-80 md:max-h-60 lg:max-h-72 object-cover object-top"
            src={imageSrc}
            alt={`Step ${step} image`}
          />
        )}
      </div>
    </Card>
  );
};
