import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import USIcon from "@/assets/Img/Flags/USIcon.png";
import MXIcon from "@/assets/Img/Flags/MXIcon.png";
import classNames from "classnames";

interface LanguageSelectorProps {
  className?: string;
  onClick?: () => void;
}

export const LanguageSelector = ({
  className,
  onClick,
}: LanguageSelectorProps) => {
  const { i18n } = useTranslation("global");
  const currentLanguage = i18n.language;

  const [languageTouched, setLanguageTouched] = useState<boolean>(false);

  return (
    <div className={className}>
      <div
        className={classNames(
          "h-10 flex gap-2 text-white items-center lg:hover:opacity-90 rounded px-2 -ml-2 cursor-pointer",
          {
            "bg-gray-800": languageTouched,
          }
        )}
        onTouchStart={() => setLanguageTouched(true)}
        onTouchEnd={() => setLanguageTouched(false)}
        onClick={onClick && onClick}
      >
        <img
          className="h-6 lg:h-8 w-6 lg:w-8 rounded-full object-cover"
          src={currentLanguage === "en" ? USIcon : MXIcon}
          alt="Language flag"
        />
        <p className="lg:hidden">{currentLanguage?.toUpperCase()}</p>
        <ChevronDownIcon className="w-4 h-4 lg:hidden" />
      </div>
    </div>
  );
};
