import { CheckIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";

interface PointCheckProps {
  backgroundColor?: string;
  text: string;
  className?: string;
}

export const PointCheck = ({
  text,
  backgroundColor,
  className,
}: PointCheckProps) => {
  return (
    <div className={classNames("flex items-center my-4", className)}>
      <div className={`rounded-full h-6 w-6 p-1 mr-2.5 ${backgroundColor}`}>
        <CheckIcon className="w-4 h-4 flex-shrink-0 text-white font-semibold" />
      </div>
      <p className="text-xs">
        <span
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </p>
    </div>
  );
};
